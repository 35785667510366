// (Copyright) Confluent, Inc.
// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  analytics,
  ButtonPrimaryMedium,
  ButtonSecondaryMedium,
  Card,
  Label,
  LabeledInput,
} from '@confluent/core';

import URLS from '../../constants/urls';

const CardTitle = styled.h3`
  margin: 0 0 24px;
  font-size: 24px;
  line-height: 1.3;
`;

const Input = styled(LabeledInput)`
  width: 100%;
  margin: 0 0 24px;
`;

const CardText = styled.p`
  margin: 18px 0 0;
`;

const PrivacyLink = styled.a`
  font-weight: 500;
  text-decoration: underline;
`;

const CancelButton = styled(ButtonSecondaryMedium)`
  margin-left: 12px;
`;

type NewsletterSignupFormProps = {
  handleCancel?: () => void,
  setEmailSubmitted: (arg1: boolean) => void,
};

const NewsletterSignupForm = ({ handleCancel, setEmailSubmitted }: NewsletterSignupFormProps) => {
  const [email, setEmail] = useState('');

  const handleFormSubmit = (event: Event) => {
    event.preventDefault();
    analytics.track({ name: 'Hub Newsletter sign up', properties: { email } });
    setEmailSubmitted(true);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Label id="newsletterEmail" showLabel={true} text="Email">
        <Input
          id="newsletterEmail"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
          placeholder="email"
          required={true}
          type="email"
          value={email}
        />
      </Label>
      <ButtonPrimaryMedium text="Sign up" type="submit" />
      {handleCancel && <CancelButton onClick={handleCancel} text="No thanks" type="button" />}
    </form>
  );
};

const NewsletterSignupCard = () => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <Card highlight={true} highlightPosition="top">
      {submitted ? (
        <CardTitle>You have been successfully added to our list.</CardTitle>
      ) : (
        <>
          <CardTitle>Be the first to know about new and updated plugins</CardTitle>
          <NewsletterSignupForm setEmailSubmitted={setSubmitted} />
          <CardText>
            By subscribing you understand we will process your personal information in accordance
            with our <PrivacyLink href={URLS.PRIVACY_POLICY}>Privacy Statement</PrivacyLink>.
          </CardText>
        </>
      )}
    </Card>
  );
};

export default NewsletterSignupCard;
