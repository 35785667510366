// (Copyright) Confluent, Inc.
// @flow

import * as React from 'react';
import styled from 'styled-components';
import { LegacyTooltip, Icon } from '@confluent/core';

import CheckboxGroup from '../../CheckboxGroup';
import { INITIAL_FILTERS, type Filters as FiltersT } from '../../../models/plugin';
import { tooltips } from '../../../constants/tooltips';

import {
  supportOptions,
  licenseOptions,
  categoriesOptions,
  operationOptions,
  verificationOptions,
} from './constants/options';
import { FilterProperties } from './constants/properties';

type Props = {
  onChange: (FiltersT) => void,
  value: FiltersT,
};

const Filters = ({ onChange, value }: Props) => {
  const values = value || INITIAL_FILTERS;
  const handleChange = (key) => (nextValues) => {
    const updatedValues: FiltersT = { ...values };
    updatedValues[key] = nextValues;
    onChange(updatedValues);
  };

  return (
    <>
      <Header>Filters</Header>

      <Filter
        onChange={handleChange(FilterProperties.Categories)}
        options={categoriesOptions}
        values={values[FilterProperties.Categories]}
      >
        Categories
        <LegacyTooltip inline={true} tipBody={tooltips.categories}>
          <Icon name="info" size={12} />
        </LegacyTooltip>
      </Filter>

      <Filter
        onChange={handleChange(FilterProperties.Operation)}
        options={operationOptions}
        values={values[FilterProperties.Operation]}
      >
        Operation
        <LegacyTooltip inline={true} tipBody={tooltips.operation}>
          <Icon name="info" size={12} />
        </LegacyTooltip>
      </Filter>

      <Filter
        onChange={handleChange(FilterProperties.Verification)}
        options={verificationOptions}
        values={values[FilterProperties.Verification]}
      >
        Verification
        <LegacyTooltip inline={true} tipBody={tooltips.verification}>
          <Icon name="info" size={12} />
        </LegacyTooltip>
      </Filter>

      <Filter
        onChange={handleChange(FilterProperties.EnterpriseSupport)}
        options={supportOptions}
        values={values[FilterProperties.EnterpriseSupport]}
      >
        Support
        <LegacyTooltip inline={true} tipBody={tooltips.support}>
          <Icon name="info" size={12} />
        </LegacyTooltip>
      </Filter>

      <Filter
        onChange={handleChange(FilterProperties.LicenseType)}
        options={licenseOptions}
        values={values[FilterProperties.LicenseType]}
      >
        License
        <LegacyTooltip inline={true} tipBody={tooltips.licence}>
          <Icon name="info" size={12} />
        </LegacyTooltip>
      </Filter>
    </>
  );
};

export default Filters;

export { INITIAL_FILTERS } from '../../../models/plugin';

const Filter = ({ children, options, values, onChange }) => (
  <Wrapper>
    <Title>{children}</Title>
    <CheckboxGroup onChange={onChange} options={options} values={values} />
  </Wrapper>
);

const Wrapper = styled.div`
  margin-bottom: 48px;
`;

const Header = styled.div`
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
  color: var(--DIM_DARKER);
  font-weight: bold;
`;

const Title = styled.div`
  font-size: 18px;
  line-height: 36px;
  border-bottom: 2px solid var(--DIM_LIGHTER);
  margin-bottom: 16px;

  svg {
    vertical-align: middle;
    margin-left: 4px;
  }
`;
