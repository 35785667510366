// (Copyright) Confluent, Inc.
// @flow
import React, { useCallback, useMemo } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { Body, HStack, Icon } from '@confluent/core';

const generatePageRange = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from(Array(length), (_, index) => start + index);
};

/**
 * Example page items:
 * [       1,              ...,         5, 6, 7, 8, 9,         ...,               20       ]
 * | leading pages | leading ellipsis | sliding pages | trailing ellipsis | trailing pages |
 *
 * [       1,              ...,       5, 6, 7, 8, 9, 10]
 * | leading pages | unified ellipsis | trailing pages |
 */
export const usePageItems = ({
  current,
  currentMax,
  ellipsisPagesCount,
  neighborPagesCount,
  total,
}: {
  current: number,
  currentMax?: number,
  ellipsisPagesCount: number,
  neighborPagesCount: number,
  total?: number,
}) => {
  const leadingPages = useMemo(() => {
    const leadingPagesStart = 1;

    let leadingPagesEnd;
    if (current > ellipsisPagesCount + neighborPagesCount + 1) {
      // e.g. [1, ..., 4, 5, 6 (current), 7, 8, ..., 20]
      leadingPagesEnd = 1;
    } else {
      // e.g. [1, 2, 3, 4, 5 (current), 6, ..., 20],
      // [1, 2, 3 (current), 4]
      leadingPagesEnd = Math.min(ellipsisPagesCount + neighborPagesCount + 2, currentMax);
    }

    return generatePageRange(leadingPagesStart, leadingPagesEnd);
  }, [current, currentMax, ellipsisPagesCount, neighborPagesCount]);

  const slidingPages = useMemo(() => {
    const slidingPagesStart = current - neighborPagesCount;
    const slidingPagesEnd = current + neighborPagesCount;

    if (
      slidingPagesStart > ellipsisPagesCount + 1 &&
      slidingPagesEnd < currentMax - ellipsisPagesCount
    ) {
      // e.g. [1, ..., 4, 5, 6 (current), 7, 8, ..., 20]
      return generatePageRange(slidingPagesStart, slidingPagesEnd);
    } else {
      return [];
    }
  }, [current, currentMax, ellipsisPagesCount, neighborPagesCount]);

  const trailingPages = useMemo(() => {
    const leadingPagesEnd = leadingPages[leadingPages.length - 1];
    const trailingPagesEnd = currentMax;

    if (leadingPagesEnd === currentMax) {
      return [];
    }

    let trailingPagesStart;
    if (current < currentMax - ellipsisPagesCount - neighborPagesCount) {
      // e.g. [1, ..., 13, 14, 15 (current), 16, 17, ..., 20]
      trailingPagesStart = currentMax;
    } else {
      trailingPagesStart =
        currentMax - ellipsisPagesCount - neighborPagesCount - 1 <= leadingPagesEnd
          ? // e.g. [1, 2, 3, 4 (current), 5, 6, 7 (trailing pages start), 8]
            leadingPagesEnd + 1
          : // e.g. [1, ..., 15, 16, 17 (current), 18, 19, 20]
            currentMax - ellipsisPagesCount - neighborPagesCount - 1;
    }

    return generatePageRange(trailingPagesStart, trailingPagesEnd);
  }, [current, currentMax, ellipsisPagesCount, leadingPages, neighborPagesCount]);

  const unifiedEllipsis = useMemo(() => {
    const leadingPagesEnd = leadingPages[leadingPages.length - 1];
    const trailingPagesStart = trailingPages[0];

    if (
      slidingPages.length === 0 &&
      trailingPagesStart != null &&
      trailingPagesStart - leadingPagesEnd > ellipsisPagesCount
    ) {
      return ['...'];
    } else {
      return [];
    }
  }, [ellipsisPagesCount, leadingPages, slidingPages.length, trailingPages]);

  const pageItems = useMemo(() => {
    // In the case where currentMax is a small number, e.g. [1, 2, 3, 4, 5]
    if (ellipsisPagesCount * 2 + neighborPagesCount + 2 >= currentMax) {
      return [...generatePageRange(1, currentMax), ...(total == null ? ['...'] : [])];
    }

    let items;
    if (slidingPages.length === 0) {
      // e.g. [1, 2, 3, 4, 5 (current), 6, ...] (without a determined total),
      // [1, 2, 3, 4, 5 (current), 6, ..., 20] (with a determined total)
      items = [
        ...leadingPages,
        ...unifiedEllipsis,
        ...(total == null && trailingPages.length === 1 ? [] : trailingPages),
      ];
    } else {
      // e.g. [1, ..., 4, 5, 6 (current), 7, 8, ...] (without a determined total),
      // [1, ..., 4, 5, 6 (current), 7, 8, ..., 20] (with a determined total)
      items = [
        ...leadingPages,
        ...['...'],
        ...slidingPages,
        ...['...'],
        ...(total == null && trailingPages.length === 1 ? [] : trailingPages),
      ];
    }

    return total == null && items[items.length - 1] !== '...' ? [...items, '...'] : items;
  }, [
    currentMax,
    ellipsisPagesCount,
    leadingPages,
    neighborPagesCount,
    slidingPages,
    total,
    trailingPages,
    unifiedEllipsis,
  ]);

  return pageItems;
};

const PageItem = styled.div`
  width: 42px;
  padding-bottom: 8px;
  text-align: center;
  ${({ isEllipsis }) =>
    isEllipsis
      ? css`
          padding-top: 4px;
          cursor: default;
        `
      : css`
          margin-top: -2px;
          cursor: pointer;
          &:hover {
            font-weight: 600;
          }
        `};
  ${({ highlight, theme }) =>
    highlight
      ? css`
          font-weight: 600;
          border-bottom: 4px solid ${theme.colors.border_interactive};
        `
      : ''};
`;

type Props = {
  current: number,
  currentMax?: number,
  ellipsisPagesCount?: number,
  neighborPagesCount?: number,
  onPageChange: (newPage: number) => void,
  total?: number,
};

/**
 * current: the currently selected page number
 * currentMax: the maximum page number so far
 * total: the global maximum page number
 * ellipsisPagesCount: the least number of pages represented by an ellipsis as a gap
 * neighborPagesCount: the number of pages shown on each side of the current page
 *
 * currentMax must be provided if total is unclear.
 * currentMax should be equal to total if both values are provided.
 */
const Pagination = ({
  current,
  currentMax,
  ellipsisPagesCount = 2,
  neighborPagesCount = 2,
  onPageChange,
  total,
}: Props) => {
  const theme = useTheme();

  const pageItems = usePageItems({
    current,
    currentMax: total != null ? total : currentMax,
    ellipsisPagesCount,
    neighborPagesCount,
    total,
  });
  const canClickPreviousPage = current !== 1;
  const canClickNextPage = current !== total;

  const handlePreviousPageClick = useCallback(() => {
    if (canClickPreviousPage) {
      onPageChange(current - 1);
    }
  }, [canClickPreviousPage, current, onPageChange]);

  const handleNextPageClick = useCallback(() => {
    if (canClickNextPage) {
      onPageChange(current + 1);
    }
  }, [canClickNextPage, current, onPageChange]);

  return (
    <HStack alignment="start" justification="center">
      <Icon
        css={`
          cursor: ${canClickPreviousPage ? 'pointer' : 'not-allowed'};
        `}
        fillColor={canClickPreviousPage ? undefined : theme.colors.text_disabled}
        name="chevronLeft"
        onClick={handlePreviousPageClick}
        size={16}
      />
      {pageItems.map((page, index) => {
        const isEllipsis = typeof page !== 'number';
        const isPageHighlighted = page === current;

        if (isEllipsis) {
          return (
            <PageItem isEllipsis={true} key={index}>
              <Body>{page}</Body>
            </PageItem>
          );
        }

        return (
          <PageItem
            highlight={isPageHighlighted}
            isEllipsis={false}
            key={index}
            onClick={() => onPageChange(page)}
          >
            {page}
          </PageItem>
        );
      })}
      <Icon
        css={`
          cursor: ${canClickNextPage ? 'pointer' : 'not-allowed'};
        `}
        fillColor={canClickNextPage ? undefined : theme.colors.text_disabled}
        name="chevronRight"
        onClick={handleNextPageClick}
        size={16}
      />
    </HStack>
  );
};

export default Pagination;
