// (Copyright) Confluent, Inc.

// @flow
import React from 'react';
import styled from 'styled-components';
import { InSVG, analytics } from '@confluent/core';

import cloudSvg from '../../../assets/svg/logo-cloud.svg';
import URLS from '../../../constants/urls';

type Props = {
  className?: string,
  cloudLink: boolean,
  pluginTitle?: string,
};

export const CloudIcon = ({ width, height }: { height: number, width: number }) => {
  return (
    <Icon>
      <InSVG height={height} src={cloudSvg} width={width} />
    </Icon>
  );
};

const ManagedInCloud = ({ className, cloudLink, pluginTitle }: Props) => {
  const trackCloudEvent = () =>
    analytics.track({
      name: 'Hub ConfluentCloud Link Clicked',
      properties: { title: pluginTitle },
    });

  return (
    <Wrapper className={className}>
      <IconWrapper>
        <CloudIcon height={20} width={40} />
      </IconWrapper>
      <Text>
        Available fully managed on{' '}
        {cloudLink ? (
          <Link href={URLS.SIGNUP} onClick={trackCloudEvent}>
            Confluent Cloud
          </Link>
        ) : (
          'Confluent Cloud'
        )}
      </Text>
    </Wrapper>
  );
};

export default ManagedInCloud;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    path {
      fill: var(--PRIMARY_DARK);
    }
  }
`;

const IconWrapper = styled.div`
  margin: 0 8px 0 0;
  padding: 10px 0 10px 0;
  float: left;
`;

const Text = styled.div`
  color: var(--PRIMARY_DARK);
  font-size: 14px;
  font-weight: 500;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0 0 0;
`;

const Link = styled.a`
  text-decoration: underline;
`;
