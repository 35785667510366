// (Copyright) Confluent, Inc.
// @flow

import { PluginProperties } from '../../../../constants/properties';

export const FilterProperties = {
  Categories: 'categories',
  Operation: 'operation',
  Verification: PluginProperties.Verification,
  EnterpriseSupport: PluginProperties.EnterpriseSupport,
  LicenseType: PluginProperties.LicenseType,
};
