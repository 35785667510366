// @flow
// (Copyright) Confluent, Inc.

import { useStaticQuery, graphql } from 'gatsby';

import { Plugin } from '../types';

const usePlugins = (): Plugin[] => {
  const data = useStaticQuery(graphql`
    query ($logoWidth: Int = 180, $logoHeight: Int = 180) {
      allHubPlugin(
        limit: 1000
        filter: { tag: { eq: "LATEST" } }
        sort: { fields: [cloud_availability, verificationSort], order: [DESC, DESC] }
      ) {
        nodes {
          ...PluginData
        }
      }
      file(name: { eq: "connect-icon" }) {
        ...ImageData
      }
    }
  `);

  const defaultImage = data.file;
  const plugins = data.allHubPlugin.nodes.map((item) => ({
    ...item,
    logo: item.logo || defaultImage,
  }));

  return plugins;
};

export default usePlugins;
