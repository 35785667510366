// @flow
// (Copyright) Confluent, Inc.

import React from 'react';
import styled from 'styled-components';

import type { Plugin } from '../../../types';

import Header from './Header';
import Item from './Item';
import EmptyState from './EmptyState';

type Props = {
  plugins: Array<Plugin>,
  results: number,
  ...
};

const ListPlugins = ({ plugins, results }: Props) => (
  <>
    <HeaderWrapper>
      <Header results={results} />
    </HeaderWrapper>
    {!plugins.length ? (
      <EmptyState />
    ) : (
      plugins.map((plugin) => <Item key={plugin.uniqueId} plugin={plugin} />)
    )}
  </>
);

const HeaderWrapper = styled.div`
  margin: 0;

  @media (max-width: 420px) {
    margin: 0 24px;
  }
`;

export default ListPlugins;
