// @flow
// (Copyright) Confluent, Inc.

import { type Plugin } from '../../../../types';
import { type Option, getIsConfluentManagedAvailable } from '../../../../models/plugin';
import { PluginProperties } from '../../../../constants/properties';
import { getIsSelfManagedConnector } from '../../../../utils/plugin';

export const categoriesOptions: Option[] = [
  {
    label: 'Confluent Fully-Managed Connector',
    value: (plugin: Plugin) => getIsConfluentManagedAvailable(plugin),
  },
  {
    label: 'Self-Managed Connector',
    value: (plugin: Plugin) => getIsSelfManagedConnector(plugin),
  },
  {
    label: 'Native Integration',
    value: (plugin: Plugin) => plugin.native_integration,
  },
  {
    label: 'Converter',
    value: {
      pluginProperty: PluginProperties.ComponentTypes,
      matchValue: 'converter',
    },
  },
  {
    label: 'Transformer',
    value: {
      pluginProperty: PluginProperties.ComponentTypes,
      matchValue: 'transform',
    },
  },
];

export const operationOptions: Option[] = [
  {
    label: 'Sink',
    value: {
      pluginProperty: PluginProperties.ComponentTypes,
      matchValue: 'sink',
    },
  },
  {
    label: 'Source',
    value: {
      pluginProperty: PluginProperties.ComponentTypes,
      matchValue: 'source',
    },
  },
];

export const verificationOptions: Option[] = [
  {
    label: 'Confluent Built',
    value: {
      pluginProperty: PluginProperties.Verification,
      matchValue: 'confluent_built',
    },
  },
  {
    label: 'Confluent Tested',
    value: {
      pluginProperty: PluginProperties.Verification,
      matchValue: 'confluent_tested',
    },
  },
  {
    label: 'Verified Gold',
    value: {
      pluginProperty: PluginProperties.Verification,
      matchValue: 'gold',
    },
  },
  {
    label: 'Verified Standard',
    value: {
      pluginProperty: PluginProperties.Verification,
      matchValue: 'standard',
    },
  },
  {
    label: 'None',
    value: {
      pluginProperty: PluginProperties.Verification,
      matchValue: 'none',
    },
  },
];

export const supportOptions: Option[] = [
  {
    label: 'Confluent Supported',
    value: {
      pluginProperty: PluginProperties.EnterpriseSupport,
      matchValue: 'confluent',
    },
  },
  {
    label: 'Partner Supported',
    value: {
      pluginProperty: PluginProperties.EnterpriseSupport,
      matchValue: 'partner',
    },
  },
  // TODO: Uncomment when community license type is available
  // {
  //   label: 'Community Supported',
  //   value: {
  //     pluginProperty: PluginProperties.EnterpriseSupport,
  //     matchValue: 'community',
  //   },
  // },
  {
    label: 'None',
    value: {
      pluginProperty: PluginProperties.EnterpriseSupport,
      matchValue: 'none',
    },
  },
];

export const licenseOptions: Option[] = [
  {
    label: 'Premium',
    value: {
      pluginProperty: PluginProperties.LicenseType,
      matchValue: 'premium',
    },
  },
  {
    label: 'Commercial',
    value: {
      pluginProperty: PluginProperties.LicenseType,
      matchValue: 'commercial',
    },
  },
  // TODO: Uncomment when community license type is available
  // {
  //   label: 'Community',
  //   value: {
  //     pluginProperty: PluginProperties.LicenseType,
  //     matchValue: 'community',
  //   },
  // },
  {
    label: 'Apache',
    value: {
      pluginProperty: PluginProperties.LicenseType,
      matchValue: 'free',
    },
  },
  {
    label: 'Other',
    value: (plugin) =>
      !['premium', 'commercial', 'free', null].includes(plugin[PluginProperties.LicenseType]),
  },
];
